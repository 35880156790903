<template>
    <BaseTrigger type="simple" :label="label" @click="OnTriggerClicked" :sound="sound">
        <slot></slot>
    </BaseTrigger>
</template>

<script>
import BaseTrigger from '../BaseTrigger.vue';

export default {
    props: ['url', 'label', 'sound'],
    components: {
        BaseTrigger
    },
    mounted() {

    },
    methods: {
        OnTriggerClicked() {
            
        }
    }
}
</script>

<style lang="scss">

</style>