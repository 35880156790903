<template>
    <BaseScene 
        name="changing-room" 
        :background="ResourceManager.Get('scenes.changingroom2')"
        @leave="OnLeaveScene" 
        @enter="OnEnterScene"        
    >
        <SceneTrigger scene="face-manipulation" x="2.3" y="2"/>
        <SimpleTrigger x="4.5" y="-9.5" :sound="ResourceManager.Get('shorts.clockticking')" :simple="true" @click="showWallpapers"/>
        <SceneTrigger scene="rehearsal-stage" x="34.5" y="0" :sound="ResourceManager.Get('shorts.footstepsshort')" :delay="500"/>

        <div class="wallpapers-overlay" :class="{visible: wallpapersVisible}">
            <div class="close-button" @click="hideWallpapers"></div>

            <perfect-scrollbar :options="{swipeEasing: true}">
                <div class="wallpapers">
                    <div class="wallpaper" v-for="wallpaper in cms.get('changingroom', 'de').get('wallpapers', 'de')" :key="wallpaper.image">
                        <img :src="cms.media(wallpaper.image)" v-if="!wallpaper.link">
                        <a v-else :href="wallpaper.link" target="_blank">
                            <img :src="cms.media(wallpaper.image)">
                        </a>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    </BaseScene>
</template>

<script>
// TODO: Schriftzug "Probebühne" auf Tür
import BaseScene from '../BaseScene.vue'
import SceneTrigger from '../triggers/SceneTrigger.vue';
import SimpleTrigger from '../triggers/SimpleTrigger.vue';
import ResourceManager from "@/classes/resource-manager";
import { Howl } from 'howler';

export default {
    inject: {
        cms: {
            default: null,
        },
    },    
    components: {
        BaseScene,
        SimpleTrigger,
        SceneTrigger
    },
    data() {
        return {
            ResourceManager,
            bgm: null,
            wallpapersVisible: false
        };
    },
    mounted() {

    },
    methods: {
        showWallpapers() {
            this.wallpapersVisible = true;
        },
        hideWallpapers() {
            this.wallpapersVisible = false;
        },
        OnEnterScene() {
            if (!this.bgm) {
                this.bgm = new Howl({
                    src: [ResourceManager.Get('ambience.changingroom')],
                    loop: true,
                    volume: 1
                });
            }
    
            if (!this.bgm.playing()) {
                this.bgm.play();
            }
        },
        OnLeaveScene() {
            this.bgm.stop();
        }
    }    
}
</script>

<style lang="scss">
.scene-changing-room {
    .wallpapers-overlay {
        position: fixed;
        background: rgba(0, 0, 0, 0.7);
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 91;
        padding: 20px;
        opacity: 0;
        pointer-events: none;
        transition: .35s;

        &.visible {
            opacity: 1;
            pointer-events: all
        }

        .close-button {
            @extend .font-hl;
            color: white;
            position: absolute;
            right: 83px;
            top: 12px;
            cursor: pointer;
            padding-bottom: 3px;
            overflow: hidden;
            display: flex;
            z-index: 81;

            &:after {
                content: '\00d7';
                font-size: 100px;

            }
        }     

        .ps {
            height: 100%;
        }
        
        .wallpapers {
            display: flex;
            flex-wrap: wrap;
            padding-right: 110px;
            padding-bottom: 100px;

            .wallpaper {
                width: calc(25% - 40px);
                margin: 20px;

                img {
                    width: 100%;
                }
            }
        }
    }
}
</style>